import { ViewEncapsulation } from '@angular/core';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { StateService } from '@uirouter/angular';

import { User, UserModel } from '@ui-resources-angular';
import { InboxMode } from '../inbox.component';
import {
  InboxActivityQuery,
  InboxConversationQuery
} from '../../../../common/services/inbox-query-factory/inbox-query-factory.service';
import {
  Filter,
  FiltersComponent
} from '../../../../common/components/filters';
import { InboxQueryResultListItem } from '../../../../common/services/inbox-query-factory/queries/common';
import { CompanyService } from '../../../../common/services/company/company.service';
@Component({
  selector: 'ssi-inbox-sidebar',
  templateUrl: './inbox-sidebar.component.html',
  styleUrls: ['./inbox-sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InboxSidebarComponent implements OnInit {
  @Input() activityQuery: InboxActivityQuery;
  @Input() inboxMode: InboxMode;
  @Input() inboxQuery: InboxActivityQuery | InboxConversationQuery;
  @Input() activeFilters: Filter[] = [];
  @Input() filtersComponentRef: FiltersComponent;
  @Input() isLiveChatActive: boolean;
  @Input() newMessagesFilteredCount: number;
  @Input() newMessagesTotalCount: number;
  @Input() selectedResults: InboxQueryResultListItem[];
  @Input() tutorialVideos: Array<any>;
  @Input() userEngagementStatistics: {
    actioned_by_user: number;
    assigned_to_user: number;
    average_response_time: number;
  };
  @Input() hasConversationPushModeEnabled: boolean;
  @Input() agentOfflineForSocial: boolean;
  @Input() isSocialPushMode: boolean;
  @Input() resultsOverlay: boolean;
  @Input() sidebarCollapsed: boolean;
  @Input() spamDetectionEnabled?: boolean;

  @Output() onDeselectSelectedResults = new EventEmitter();
  @Output() onEditFilter = new EventEmitter();
  @Output() onEditPreset = new EventEmitter();
  @Output() onExportInbox = new EventEmitter();
  @Output() onRemoveFilter = new EventEmitter();
  @Output() onClearFilters = new EventEmitter<void>();
  @Output() onShowVideoTutorial = new EventEmitter();
  @Output() onToggleBulkAction = new EventEmitter();
  @Output() onBulkMarkAsSpam = new EventEmitter<void>();
  @Output() onToggleFilters = new EventEmitter();
  @Output() onToggleInboxMode = new EventEmitter();
  @Output() onMarkAllActioned = new EventEmitter<void>();
  @Output() onMarkAllUnactioned = new EventEmitter<void>();
  @Output() onPresetActivated = new EventEmitter();
  @Output() onClose = new EventEmitter();

  @Output() onToggleActiveFilters = new EventEmitter();
  @Output() onTogglePresets = new EventEmitter();
  @Output() onToggleUsefulThings = new EventEmitter();

  sidebarFiltersExpanded: boolean;
  showBulkTip: boolean;
  showCustomPresets: boolean;
  activeFiltersVisible: boolean;
  filtersVisible: boolean;
  presetsVisible: boolean;
  usefulThingsVisible: boolean;

  constructor(
    protected state: StateService,
    protected company: CompanyService
  ) {}

  async ngOnInit() {
    this.spamDetectionEnabled = await this.company.hasFeatureAccess(
      'SPAM_DETECTION'
    );
  }

  activatePreset(preset) {
    this.inboxQuery.activatePreset(preset);
    if (this.isSocialPushMode) {
      this.onPresetActivated.emit();
    }
  }

  isActivityQuery(): boolean {
    return this.inboxQuery instanceof InboxActivityQuery;
  }

  enterPushMode(): void {
    this.state.go('auth.inbox', {
      push: true,
      mode: 'conversation'
    });
  }

  exitPushMode(): void {
    this.state.go(
      'auth.inbox',
      { assignmentQuery: '' }, //{ query: JSON.stringify({ assignment: '' }) },
      { reload: true, inherit: false }
    );

    // this.inboxQuery.activatePreset({ query: {} });
  }
}
