import { ViewEncapsulation } from '@angular/core';
import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy
} from '@angular/core';

import { User, UserModel } from '@ui-resources-angular';
import {
  InboxActivityQuery,
  InboxConversationQuery
} from '../../../../common/services/inbox-query-factory/inbox-query-factory.service';
import {
  AgentStatus,
  AgentStatusID,
  AgentStatuses,
  ConversationPushModeService
} from '../../../../common/services/conversation-push-mode/conversation-push-mode.service';
import { InboxQueryType } from '../../../../common/services/inbox-query-factory/queries/common';
import { Subject, Subscription, merge } from 'rxjs';
import {
  SocialPushModeCheckedResponse,
  SocketEventManagerService
} from '../../../../common/services/sockets/socket-event-manager.service';
import { takeUntil } from 'rxjs/operators';
import { Colleague, ColleaguesService } from '../../../../common/services/api';
import { StateService } from '@uirouter/angular';

export interface SocialPushModeStats {
  user: any;
  assigned: number;
  status: AgentStatus;
  isManageable: boolean;
}

@Component({
  selector: 'ssi-inbox-dashboard',
  templateUrl: './inbox-dashboard.component.html',
  styleUrls: ['./inbox-dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InboxDashboardComponent implements OnInit, OnDestroy {
  @Input() authUser: User;
  @Input() hasNewlyResolvedConversation: boolean;
  @Input() hasConversationPushModeEnabled: boolean;
  @Input() userEngagementStatistics: any;
  @Input() inboxQuery: InboxActivityQuery | InboxConversationQuery;
  @Input() isSocialPushMode: boolean;

  @Output() onClose = new EventEmitter();
  @Output() onShowVideoTutorial = new EventEmitter();
  @Output() onExportInbox = new EventEmitter();

  @Output() onDismissConversationResolutionConfirmation = new EventEmitter();

  userAvatar: string;
  defaultGravatarImg: string;
  InboxQueryType = InboxQueryType;
  currentStatus: { id: string; label: string } = AgentStatuses.offline;
  statuses: AgentStatus[] = Object.values(AgentStatuses);
  filteredStatuses = this.statuses;
  socialPushModeStats: SocialPushModeCheckedResponse;
  agentsStats: Array<SocialPushModeStats> = [];
  $socialPushModeChecked: Subscription;
  onDestroy = new Subject();
  isActiveAgent = true;
  isPushModeAdmin = false;
  showMenu = false;

  constructor(
    private conversationPushModeService: ConversationPushModeService,
    private state: StateService,
    protected socketEventManagerService: SocketEventManagerService,
    protected colleaguesService: ColleaguesService,
    protected userModel: UserModel
  ) {
    this.defaultGravatarImg = encodeURIComponent(
      'https://i.imgur.com/SVoRVQT.png'
    );
  }

  async ngOnInit() {
    const socialPushSettings = await this.conversationPushModeService.getCompanySettings();
    this.hasConversationPushModeEnabled =
      socialPushSettings && socialPushSettings.enabled;
    if (this.hasConversationPushModeEnabled) {
      await this.checkSocialPushModeStats();
      await this.getAgentStatus();

      this.conversationPushModeService.pushModeStatusChange.subscribe(
        (status) => {
          const formattedStatus = status.toLowerCase();
          this.currentStatus = AgentStatuses[formattedStatus];
        }
      );
    }

    this.userAvatar = `https://www.gravatar.com/avatar/${this.authUser.email_address}?s=200&d=${this.defaultGravatarImg}`;

    this.isPushModeAdmin = await this.authUser.hasCompanyPermission(
      'push_mode_administrator'
    );
  }

  async checkSocialPushModeStats() {
    const colleagues = await this.colleaguesService.getAllActive();
    this.agentsStats = [];
    this.agentsStats.push({
      user: this.authUser,
      assigned: 0,
      status: AgentStatuses.offline,
      isManageable: true
    });
    this.agentsStats = colleagues.map((c) => {
      return {
        user: c,
        status: AgentStatuses[AgentStatuses.offline.label.toLowerCase()],
        assigned: 0,
        isManageable: true
      };
    });
    this.sortAgentsList();
    this.$socialPushModeChecked = this.socketEventManagerService.socialPushModeChecked
      .pipe(takeUntil(this.onDestroy))
      .subscribe((pushModeStats: SocialPushModeCheckedResponse) => {
        this.socialPushModeStats = pushModeStats;

        this.agentsStats = this.agentsStats.map((agent) => {
          if (pushModeStats.user_ids.includes(parseInt(agent.user.id, 10))) {
            agent.status =
              AgentStatuses[AgentStatuses.online.label.toLowerCase()];
            agent.assigned =
              pushModeStats.count_assigned_by_user_id[agent.user.id] || 0;
          } else if (
            pushModeStats.user_ids_busy.includes(parseInt(agent.user.id, 10))
          ) {
            agent.status =
              AgentStatuses[AgentStatuses.busy.label.toLowerCase()];
            agent.assigned =
              pushModeStats.count_assigned_by_user_id[agent.user.id] || 0;
          } else {
            agent.status =
              AgentStatuses[AgentStatuses.offline.label.toLowerCase()];
            agent.assigned = 0;
          }
          return agent;
        });

        this.sortAgentsList();
      });
  }

  agentIsUser(agent) {
    return agent.user.id === this.authUser.id;
  }

  onlyShowAvailableStatuses() {
    this.filteredStatuses = this.statuses.filter(
      (s) => s.id !== this.currentStatus.id
    );
  }

  sortAgentsList() {
    const agentsWithoutAuthUser = this.agentsStats.filter(
      (a) => a.user.id !== this.authUser.id
    );

    this.agentsStats = [
      ...this.agentsStats.filter((a) => a.user.id === this.authUser.id),
      ...agentsWithoutAuthUser.filter(
        (a) => a.status.id === AgentStatusID.Online
      ),
      ...agentsWithoutAuthUser.filter(
        (a) => a.status.id === AgentStatusID.Busy
      ),
      ...agentsWithoutAuthUser.filter(
        (a) => a.status.id === AgentStatusID.Offline
      )
    ];
  }

  async getAgentStatus() {
    try {
      const response = await this.conversationPushModeService.getAgentStatus();
      const status = response.toLowerCase();
      this.currentStatus = AgentStatuses[status];
      this.updateAuthUserStatus(this.authUser.id, AgentStatuses[status]);
    } catch (error) {
      console.log('Error getting the agent status:', error);
    }
  }

  async changeAgentStatus(newStatus: AgentStatus, userId: string) {
    try {
      const response = await this.conversationPushModeService.changeAgentStatus(
        newStatus.id,
        userId
      );
      const status = response.toLowerCase();
      if (userId === this.authUser.id) {
        this.currentStatus = AgentStatuses[status];
      }
      this.updateAuthUserStatus(userId, AgentStatuses[status]);
    } catch (error) {
      console.error('Error changing agent status:', error);
    }
  }

  updateAuthUserStatus(userId, status) {
    const authUserIndex = this.agentsStats.findIndex(
      (a) => a.user.id === userId
    );
    this.agentsStats[authUserIndex].status =
      AgentStatuses[status.id.toLowerCase()];
    this.filteredStatuses = this.statuses.filter((s) => s.id !== status.id);
  }

  openAssigned(agent) {
    this.state.go(
      'auth.inbox',
      {
        mode: 'conversation',
        assignmentUserId: agent.user.id
      },
      {
        reload: true,
        inherit: false
      }
    );
  }

  ngOnDestroy() {
    this.onDestroy.next();

    if (this.$socialPushModeChecked) {
      this.$socialPushModeChecked.unsubscribe();
    }
  }
}
