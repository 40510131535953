import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { ServicesModule } from '../../../common/services/services.module';
import { InboxQueryResultListModule } from './inbox-query-result-list/inbox-query-result-list.module';
import { InboxUsefulThingsModule } from './inbox-useful-things/inbox-useful-things.module';
import { InboxSearchResultsModule } from './inbox-search-results/inbox-search-results.module';
import { InboxPriorityModule } from '../../../common/components/inbox-priority/inbox-priority.module';
import { InboxSidebarModule } from './inbox-sidebar/inbox-sidebar.module';
import { InboxComponent } from './inbox.component';
import { TranslateModule } from '@ngx-translate/core';
import { InboxActivityModule } from '../../../common/components/inbox-activity/inbox-activity.module';
import { InboxSearchResultsOverlayModule } from './inbox-search-results-overlay/inbox-search-results-overlay.module';
import { FormsModule } from '@angular/forms';
import { FilterModule } from '../../../common/pipes/filter/filter.module';
import { SortByModule } from '../../../common/pipes/sort-by/sort-by.module';
import { ListSelectModule } from '../../../common/components/list-select/list-select.module';
import { InboxDashboardModule } from './inbox-dashboard/inbox-dashboard.module';
import { LiveChatDashboardModule } from '../../../common/components/live-chat/live-chat-dashboard/live-chat-dashboard.module';
import { FilterByStringModule } from '../../../common/pipes/filter-by-string/filter-by-string.module';
import { FiltersModule } from '../../../common/components/filters';
import { ContextMenuModule } from '../../../common/components/context-menu/context-menu.module';
import { FilterPartialModule } from '../../../common/pipes/filter-partial/filter-partial.module';
import { BulkProgressModalModule } from '@orlo/common/components/bulk-progress-modal/bulk-progress-modal.module';
import { BulkProgressModalComponent } from '@orlo/common/components/bulk-progress-modal/bulk-progress-modal.component';
import { ConfirmLeaveModalModule } from '@orlo/common/components/confirm-leave-modal/confirm-leave-modal.module';

@NgModule({
  imports: [
    CommonModule,
    ServicesModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.inbox',
          url:
            '/inbox?activity&conversation&push&reply&mode&assignmentUserId&assignmentQuery&authorQuery',
          component: InboxComponent,
          resolve: InboxComponent.resolve,
          params: {
            activity: {
              dynamic: true,
              optional: true
            },
            conversation: {
              dynamic: true,
              optional: true
            },
            push: {
              dynamic: false,
              optional: true
            },
            reply: {
              dynamic: true,
              optional: true
            }
          }
        }
      ]
    }),
    FormsModule,
    InboxQueryResultListModule,
    InboxUsefulThingsModule,
    InboxSearchResultsModule,
    InboxSearchResultsOverlayModule,
    InboxPriorityModule,
    InboxSidebarModule,
    InboxActivityModule,
    InboxDashboardModule,
    LiveChatDashboardModule,
    TranslateModule,
    FilterModule,
    FilterByStringModule,
    FilterPartialModule,
    SortByModule,
    FiltersModule,
    ContextMenuModule,
    ListSelectModule,
    BulkProgressModalModule,
    ConfirmLeaveModalModule
  ],
  declarations: [InboxComponent],
  entryComponents: [BulkProgressModalComponent]
})
export class InboxModule {}
