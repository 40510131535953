import { ViewEncapsulation } from '@angular/core';

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { StateService } from '@uirouter/angular';

import { ActivityModel, ConversationStatus } from '@ui-resources-angular';
import strings from '@orlo/library/constants/strings';
import {
  inboxActivityQuerySort,
  InboxActivityQuery
} from '@orlo/common/services/inbox-query-factory/inbox-query-factory.service';
import {
  InboxConversationQuery,
  inboxConversationQuerySort
} from '@orlo/common/services/inbox-query-factory/queries/conversation';
import {
  InboxQueryType,
  InboxQueryResultListItem
} from '@orlo/common/services/inbox-query-factory/queries/common';
import { mapToIterable } from '@orlo/common/utils';
import { InboxMode } from '../inbox.component';

@Component({
  selector: 'ssi-inbox-search-results',
  templateUrl: './inbox-search-results.component.html',
  styles: [],
  styleUrls: ['./inbox-search-results.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InboxSearchResultsComponent implements OnInit, OnChanges {
  @Input() activeConversationThread: any;
  @Input() activityQuery: any;
  @Input() inboxMode: InboxMode;
  @Input() inboxQuery: InboxActivityQuery | InboxConversationQuery;
  @Input() activeConversationQuery: InboxActivityQuery | InboxConversationQuery;
  @Input() holdConversationQuery: InboxActivityQuery | InboxConversationQuery;
  @Input() isConversationThreadActive: boolean;
  @Input() isLiveChatActive = false;
  @Input() isLiveChatEnabled = false;
  @Input() multiSelectedResults;
  @Input() newMessagesUnfilteredCount;
  @Input() hasConversationPushModeEnabled: boolean;
  @Input() isSocialPushMode = false;
  @Input() showOnHold = true;
  @Input() showActive = true;
  @Input() newIncomingReply: InboxQueryResultListItem;

  @Output() onConversationThreadChange = new EventEmitter<void>();
  @Output() multiDeselectResult = new EventEmitter<InboxQueryResultListItem>();
  @Output() multiSelectResult = new EventEmitter<InboxQueryResultListItem>();
  @Output() onChangeActivity = new EventEmitter<void>();
  @Output() onExitConversation = new EventEmitter<void>();
  @Output() onExitLiveChat = new EventEmitter<void>();
  @Output() onSidebarButtonPress = new EventEmitter<void>();
  @Output() onResolveConversation = new EventEmitter<any>();
  @Output() reloadResults = new EventEmitter<void>();
  @Output() isLiveChatActiveChange = new EventEmitter<boolean>();

  @Output() showActiveChange = new EventEmitter<boolean>();
  @Output() showOnHoldChange = new EventEmitter<boolean>();

  inboxQueryType = InboxQueryType;

  sortOptions = {
    activity: mapToIterable(inboxActivityQuerySort),
    conversation: mapToIterable(inboxConversationQuerySort)
  };
  ConversationStatus: ConversationStatus;
  presets: any;

  constructor(
    private state: StateService,
    private activityModel: ActivityModel
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['inboxQuery'] && this.inboxQuery) {
      this.presets = {};
      Object.values(this.inboxQuery.presets).forEach((preset) => {
        this.presets[preset.key] = preset;
      });
    }
  }

  public get isConversationModeActive() {
    return this.inboxMode === strings.conversation;
  }

  public get selectedSortOption() {
    if (!(!!this.inboxQuery && !!this.inboxQuery.params)) {
      return;
    }

    return this.inboxQuery.params.sort;
  }

  updateLiveChatStatus(value: boolean) {
    if (!value) {
      this.onExitLiveChat.emit();
    }

    this.isLiveChatActiveChange.emit(value);
  }

  refreshResultsFromOption(option) {
    if (!(!!this.inboxQuery && !!this.inboxQuery.params)) {
      return;
    }

    this.inboxQuery.params.sort = option.value;
    this.inboxQuery.params.order = option.order;
    this.reloadResults.emit();
  }

  onConversationEntered(list: 'active' | 'onHold') {
    if (list === 'onHold') {
      this.showActiveChange.emit(false);
    } else {
      this.showOnHoldChange.emit(false);
    }
  }

  backToClassicMode() {
    this.state.go('auth.inbox');
  }
  exitConversation() {
    this.onExitConversation.emit();
    this.showActiveChange.emit(true);
    this.showOnHoldChange.emit(true);
  }

  onExit() {
    // console.log('onExit!!');
  }

  exitPushMode(): void {
    this.state.go(
      'auth.inbox',
      { assignmentQuery: '' }, //{ query: JSON.stringify({ assignment: '' }) },
      { reload: true, inherit: false }
    );

    // this.inboxQuery.activatePreset({ query: {} });
  }
}
