import { ViewEncapsulation } from '@angular/core';

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output
} from '@angular/core';
import {
  Account,
  Activity,
  ActivityAuthor,
  CrmExternalIntegration,
  CrmPerson,
  Profile,
  ProfileModel
} from '@ui-resources-angular';
import { LinkedExternalIntegration } from '../inbox-activity.component';
import { Plugin } from '../../plugin/plugin.component';
import { StateService } from '@uirouter/angular';
import { InboxMode } from '../../../../modules/auth/inbox/inbox.component';
import { ProfileHelperService } from '../../../services/profile-helper/profile-helper.service';

// ----------------

const isDebug: boolean = false;

// ----------------

@Component({
  selector: 'ssi-inbox-crm-sidebar',
  templateUrl: './inbox-crm-sidebar.component.html',
  styleUrls: ['./inbox-crm-sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InboxCrmSidebarComponent implements OnChanges {
  @Input() account: Account;
  @Input() profile: Profile;
  @Input() crmPerson: CrmPerson;
  @Input() activityProfile: ActivityAuthor;
  @Input() activity: Activity;
  @Input() personLoadingTracker: any;
  @Input() profileSentiment: any;
  @Input() selectedIntegration: LinkedExternalIntegration;
  @Input() externalIntegrationPerson: CrmExternalIntegration;
  @Input() externalIntegrations: {
    linked: LinkedExternalIntegration[];
    unlinked: CrmExternalIntegration[];
  };
  @Input() linkedSocialProfiles: any;
  @Input() plugins: Plugin[];
  @Input() initialisedPlugins: any;

  notesOpen: boolean;
  filterType: string;

  @Output() onHide = new EventEmitter();
  @Output() onExpandCrmProfile = new EventEmitter();
  @Output() closeSelectedIntegration = new EventEmitter();
  @Output() unlinkIntegration = new EventEmitter();
  @Output() showExternalCrmPerson = new EventEmitter();
  @Output() linkIntegration = new EventEmitter();
  @Output() handlePluginCallback = new EventEmitter();

  constructor(
    private state: StateService,
    private profileHelper: ProfileHelperService
  ) {}

  ngOnChanges(changes) {
    if (changes.profile || changes.crmPerson) {
      this.notesOpen = false;
    }
  }

  toggleNotes() {
    this.notesOpen = !this.notesOpen;
  }

  checkIfLinkedInPlugin(context) {
    if (this.crmPerson && context) {
      const personLinkedInPlugin = this.crmPerson.plugins.find(
        (linkedPlugin) => linkedPlugin.plugin_id === context.nativeElement.id
      );
      if (personLinkedInPlugin) {
        return 'crm';
      } else {
        return 'link';
      }
    } else {
      return false;
    }
  }

  showInboxListWithThisName() {
    this.state.go(
      'auth.inbox',
      {
        // modifiedQuery: JSON.stringify({
        //   author: this.activityProfile.name
        // }),
        mode: InboxMode.Activity,
        authorQuery: this.activityProfile.name,
      },
      {
        reload: true
      }
    );
  }

  get profileAvailable(): boolean {
    return (
      !!(this.profile && this.profile.id) &&
      this.profile.account.account_type_id !== '6'
    );
  }

  viewProfile(params: { accountId: string; profileId: string }) {
    this.profileHelper.viewProfile(params);
  }
}
